import request from '@/utils/request'
import setting from '@/setting'

import service from "../utils/journalRequest";
import Qs from "qs";

export function getTableData (data) {
  return request({
    url: '/history/getHistoryScanRecord',
    method: 'post',
    data
  })
}
export function getRedirectUrl (data) {
  return request({
    url: '/index/getRedirectUrl',
    method: 'post',
    data
  })
}
export function getLocationParams (data) {
  return request({
    url: '/index/locationParams',
    method: 'post',
    data
  })
}
export function getUserInfo (data) {
  return request({
    url: '/index/getInfo',
    method: 'post',
    data
  })
}
export function getImgUrl (data) {
  return request({
    url: '/luckyDraw/queryTurntableImagesAndSweepstakesRules',
    method: 'post',
    data,
    imgPathAddDomain: true
  })
}
export function getLocation (data) {
  return request({
    url: '/index/location',
    method: 'post',
    data
  })
}
export function getDrawRecord (data) {
  return request({
    url: '/luckyDraw/queryDrawRecord',
    method: 'post',
    data
  })
}
export function getWinningRecord (data) {
  return request({
    url: '/bonus/queryWinningRecord',
    method: 'post',
    data
  })
}
export function checkProjectConfig (data) {
  return request({
    url: '/projectCheck/checkProjectConfig',
    method: 'post',
    data
  })
}
export function codeCheck (data) {
  return request({
    url: '/projectCheck/codeCheck',
    method: 'post',
    data
  })
}

// 前置转盘
export function drawRecordPre (data) {
  return request({
    url: '/luckyDraw/drawRecordPre',
    method: 'post',
    data
  })
}
export function receiveRedPacket (data) {
  return request({
    url:
      setting.redEnvelopeApiType == 'DouMi'
        ? '/luckyDraw/receiveRedPacketByDouMi'
        : '/luckyDraw/receiveRedPacket',
    method: 'post',
    data
  })
}
export function selectPrizeUrl (data) {
  return request({
    url: '/bonus/selectPrizeUrl',
    method: 'post',
    data
  })
}

// 查询二维码首次使用时间
export function queryQrcodeScanFirstTime (data) {
  return request({
    url: '/bonus/queryQrcodeScanFirstTime',
    method: 'post',
    data
  })
}

// 后置中奖记录
export function winningRecordAfter (data) {
  return request({
    // url: "/bonus/queryWinningRecord",
    url: '/bonus/queryPrizeLog',
    method: 'post',
    data
  })
}

// 后置抽奖
export function drawRecordAfter (data) {
  return request({
    url: '/luckyDraw/drawRecord',
    method: 'post',
    data
  })
}
// 圈码活动规则
export function qmActivityRule (data) {
  return request({
    url: '/luckyDraw/queryActivityRule',
    method: 'post',
    data
  })
}

// 私域引流埋点请求
export function journalApplet(params) {
  return service({
   url: "/advertise/saveAdvertisesLog",
   method: "post",
   data: Qs.stringify(params),
 });
}
