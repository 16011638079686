<template>
  <div class="m-rule-cont">
    <h3>活动规则</h3>
    <p>
      <span
        >活动期间内，凡购买今麦郎“无糖茶饮料”促销装，打开瓶盖，打开手机微信扫一扫，扫描瓶盖内二维码参与活动，即有机会获得微信红包，综合中奖率30%，单个消费者当日获得微信红包个数上限为10个。</span
      >
    </p>
    <p>
      <span
        >参与方式：打开手机微信扫一扫，扫描瓶盖内二维码，扫码后按提示参与活动。</span
      >
    </p>
    <p>
      <span>活动时间：2022年1月1日00:00起至2023年3月31日18:00止。</span>
    </p>
    <p>
      <span>活动范围：全国（不包含香港、澳门、台湾地区）。</span>
    </p>
    <p>
      <span>活动瓶数：4500万瓶，有奖产品约1350万瓶，综合中奖率30%。</span>
    </p>
    <p>
      <span>活动奖品：</span>
    </p>
    <p>
      <span>一等奖：微信红包2.88元（中奖率为1%）；</span>
    </p>
    <p>
      <span>二等奖：微信红包0.88元（中奖率为10%）；</span>
    </p>
    <p>
      <span>三等奖：微信红包0.38元（中奖率为19%）。</span>
    </p>
    <p>
      <span
        >活动热线：400-707-1294（活动期间周一至周五，09:00-18:00，法定节假日除外）。</span
      >
    </p>
    <p><br /></p>
  </div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {
      ruleText: ''
    }
  },
  mounted: function () {
    this.ruleText = window.rule.text
  }
}
</script>

<style lang="less" scoped>
.m-rule-cont {
  font-size: 24px;
  line-height: 36px;
  color: #313131;
  h3 {
    margin-bottom: 5px;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
  }
  h4 {
    font-weight: bold;
  }
  b {
    display: block;
    margin-top: 6px;
  }
}
</style>
